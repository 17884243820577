import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import LazyLoad from "react-lazyload";
import "./index.css";
import { CircularProgress } from "@mui/material";
const App = lazy(() => import("./App"));
const AuthProvider = lazy(() => import("./Context"));
const BubbleChat = lazy(() =>
  import("flowise-embed-react").then((module) => ({
    default: module.BubbleChat,
  }))
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div
          className="m-auto d-flex align-items-center justify-content-center"
          style={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      }
    >
      {" "}
      <AuthProvider>
        <App />
      </AuthProvider>
      <LazyLoad height={600} offset={1000}>
        <BubbleChat
          chatflowid="bbf838a7-0364-47c4-aa1b-c99eeddd9be4"
          apiHost="https://flowiseai-railway-production-f128.up.railway.app"
        />
      </LazyLoad>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
